import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Connect Google My Business" prev={{
  name: 'Creating a Company',
  path: '/creating-a-company'
}} next={{
  name: 'Account Initialization Process',
  path: '/account-initialization-process'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Connecting Google My Business to Simple Review Management gives Simple RM permission to view the data in the Google My Business account it needs to function.`}</p>
    <p>{`Once you have clicked the connect button on the Simple RM side, the rest of the process to connect is on Google pages.`}</p>
    <p>{`The process consists of two easy steps`}</p>
    <h2 {...{
      "id": "sign-in"
    }}>{`Sign In`}</h2>
    <p>{`You must be signed into your Google account in order to grant Simple RM permission to Google My Business in step two.`}</p>
    <p>{`If you are already signed in to a Google account you may be brought directly to step two.`}</p>
    <p>{`Make sure you are signed into the Google user account that is associated with the Google My Business account you are trying to connect.`}</p>
    <h2 {...{
      "id": "grant-permission"
    }}>{`Grant Permission`}</h2>
    <p>{`You will now be presented with a screen that explains what it means to connect Simple Review Management to your Google My Business listing.`}</p>
    <p>{`Once you have read through everything and you are comfortable to proceed, confirm you would like to give Simple Review Management the permissions listed. `}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
You will see you are granting Simple RM delete permission. This is only because Google does not give us the ability to request view permissions and not this one. We will never delete anything in your account.
    </Message>
    <h2 {...{
      "id": "whats-next"
    }}>{`What’s Next`}</h2>
    <p>{`You will now be brought back to the spot where you left off in the Simple Review Management app.`}</p>
    <p>{`Simple RM now has the access it needs to function.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      